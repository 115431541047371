import {
  PaymentElement,
  LinkAuthenticationElement,
  PaymentMethodMessagingElement,
  AddressElement,
  ExpressCheckoutElement,
} from "@stripe/react-stripe-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useStripe, useElements, CustomCheckoutProvider } from "@stripe/react-stripe-js";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../../context/CartContext";
import { Actions_, AlertContext, Alert_, Builder_, Checkbox_, Dropdown_, Focus_, Grey_, Input_, Main_ ,  Main_Loader, Main_Text_Loader} from "monica-alexandria";
import { create_confirm_payment_intent, create_confirm_subscription } from "../../../services/api/Subscriptions";
import store from "../../../redux/store";
import i18n from "../../../localization/i18next";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";
import { CheckoutExtraInput } from "../../../styles/Layout.css";
import { checkVAT, countries as jsvatCountries } from 'jsvat';
import { countries as monicaCountries } from 'monica-alexandria';

const vat_CC = require('../../../config/vatCountryCodes').vatCountryCodes;

const clients = require('../../../config/index').config
const PaymentDetails = styled.div``;

export default function Payment_Details(props) {

  const stripe = useStripe();
  const elements = useElements();
  const cart  = useContext(CartContext);
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  const { alerts, setAlerts } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] =  useState(false)
  const [buttonText, setButtonText] = useState("")
  const [vatInput, setVatInput] = useState('');
  const customer = window.sessionStorage.getItem("customer");
  const user = store.getState().user.user;
  const billing  = JSON.parse(window.sessionStorage.getItem("billing"))
  const currentCart = cart.length>1 ? cart : store.getState().cart
  const lang = i18n.language ? i18n.language : "el";
  const quest = store.getState().params.quest;
  
  const GA4_QUESTAD = process.env.REACT_APP_GOOGLE_ANALYTICS_QUESTAD;
  const GA4_ADSNAP = process.env.REACT_APP_GOOGLE_ANALYTICS_ADSNAP;

  const chooseRedirectURI = (category, property) => {
    switch (true) {
      case category === 'lois':
        return `${clients['lois']}/${lang}/success-payment`;
      case category === 'bond' && property === "vandador":
        return `${clients['vandador']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
      case category === 'bond' && property === "agency":
        return `${clients['agency']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
      case category === 'dailyprofit':
        return `${clients['dailyprofit']}/${lang}/success-payment`;
      default:
        return `${clients['griphook']}/${lang}/my-plans`;
    }
  }
  
  const [messages, setMessages] = useState({
    errors: [],
    helpers: [],
    successes: [],
  });


  const handleCheckboxChange = () => {
    props?.setShowVatInput(prevState => !prevState);
  };

  const alertMessage = (message, status) => {
    
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
    setTimeout(() => {
      setAlerts((prevAlerts) => prevAlerts.slice(1));
    }, 5000);
  };

  const validateVat = (vatValue, country) => {
    let check;
    if(props?.showVatInput) {

    // Combine best practices from previous responses:
      const countryCode = vat_CC[country]; // Replace with your actual country code (avoid hardcoding if possible)
      const trimmedVatValue = vatValue.trim();

      const fullVatNumber = trimmedVatValue.startsWith(countryCode)
      ? trimmedVatValue // If it already starts with country code, use as-is
      : `${countryCode}${trimmedVatValue}`; // Otherwise, prepend country code


      check= checkVAT(fullVatNumber, jsvatCountries) || { isValid: false }; // Handle potential undefined check result
   
      if(check?.isValid) {
        let updatedBilling = {...billing, address:{country: country}, name: `${user?.firstname} ${user?.lastname}`, vat:fullVatNumber}
        window.sessionStorage.setItem("billing", JSON.stringify(updatedBilling))
      }
    }
    else check={isValid:true};
    return check
  };
  

  const handleError = (error) => {
    console.log("error", error)
    setLoading(false);
    alertMessage(t(`Error on payment form`), 'Error');
  }

  const gtag_purchase_event = () =>{
  // Send a custom event
  ReactGA.event("purchase", { 
      value: prettifyPrice(props?.total),
      category: currentCart[0]?.category,
      action: "purchase",
      coupon: props?.discount,
      trial: props?.trail,
      currency: 'EUR'
    });
  }

  const createAndConfirmSubscritpion = (customer, cart, trial, userId, email,  paymentMethod) =>{
    const updatedBilling = window.sessionStorage.getItem('billing')
    create_confirm_subscription(customer, cart, trial, userId, email, paymentMethod, quest, props?.discount, updatedBilling)
    .then(res=>{
      if(res?.code==='SCC4000') {
        setLoading(false);
        alertMessage(t("Something went wrong with stripe. Check your information and try again, or contact support"), 'Error');
      }
      else {
        setLoading(true);
        handleServerResponse(res?.data)
      } 
    })

  }

  const handleServerResponse = async (response) => {
    setLoading(true);
    if (response?.length>0 && response.some(paymentIntent => 
      paymentIntent?.last_payment_error && 
      JSON.stringify(paymentIntent?.last_payment_error) !== "{}"
    )) {
      response.forEach(paymentIntent => {
        alertMessage(t("Something went wrong with stripe. Check your information and try again, or contact support."), 'Error');
      });
      setLoading(false);
    }

    else if (response?.status === "requires_action" ) {
      // Use Stripe.js to handle the required next action
      const {
        error,
        paymentIntent,
        setupIntent
      } = await stripe.handleNextAction({
        clientSecret: response?.client_secret
      })
    
      if (error) {

        setLoading(false);
        alertMessage(t("We could not verify your payment method. Check your information and try again, or contact support."), 'Error')
        // Show error from Stripe.js in payment form
      } 
      else{
        let intent = paymentIntent ? paymentIntent : setupIntent
        createAndConfirmSubscritpion(customer, currentCart, props?.trial, user?.sub, user?.username, intent?.payment_method)
      }
    } 
    else if(response?.status === "succeeded") {
      console.log('redirect to success page')
        createAndConfirmSubscritpion(customer, currentCart, props?.trial, user?.sub, user?.username, response?.payment_method)

    }
    else {
      gtag_purchase_event()
      window.sessionStorage.removeItem('billing');
      window.sessionStorage.removeItem('customer');
      window.location.replace(chooseRedirectURI(currentCart[0]?.category, currentCart[0]?.property))
      window.localStorage.removeItem('cart');
    }

  }

  
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }
    // Create the PaymentMethod using the details collected by the Payment Element
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      elements,
      params:{billing_details: {
        address: {country: props?.selectedCountry, postal_code :"", state:"", city:"", line1:"", line2:""}
        }
      }
    });
    let valid_vat = validateVat(vatInput, paymentMethod?.billing_details?.address?.country)

    if (props.showVatInput && messages?.helpers?.length>0){
      setVatInput('')
      setMessages({...messages, errors: messages?.helpers, helpers:[], successes:[]})

      alertMessage(t(`Something went wrong. Please check your TIN.`), 'Error');
      return;
    }
   else if (error) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
      return;
    }
    else if(!valid_vat?.isValid) {
      handleError({message:t("TIN is not valid")})
      return;
    }
    // Create the PaymentIntent
    else {
      create_confirm_payment_intent(customer, currentCart, props?.trial, user?.sub, paymentMethod?.id, props?.total, props?.currency)
      .then(res => {
        if(res?.code==='SCC4000') {
          setLoading(false);
          alertMessage(t("Your card was declined. Make sure you have enough funds to complete the payment."), 'Error');
        }
        else handleServerResponse(res?.paymentAction)
      })
      .catch(err => console.log(err))
  
    // Handle any next actions or errors. See the Handle any next actions step for implementation.
    }
  };


  const checkTin = (vatValue) =>{
    setVatInput(vatValue)
    let helpers =[], successes=[];
    console.log('vatValue1', vatValue);
    
    const countryIncluded = jsvatCountries.some(country => country.codes.includes(props?.selectedCountry));

    console.log('countryIncluded && selectedCountry',countryIncluded,props?.selectedCountry, countryIncluded && props?.selectedCountry);
    
    if (countryIncluded && props?.selectedCountry){
      console.log('vatValue', vatValue, props.selectedCountry);
      
     let valid_vat = validateVat(vatValue, props?.selectedCountry)
    
     console.log('VALID VAT',valid_vat );
     if (!valid_vat?.isValid) helpers.push(t('TIN is not valid'))
     if (valid_vat?.isValid) successes.push(t('TIN is valid'))
 
      

      setMessages({...messages, helpers: helpers, successes: successes})
      console.log('here',valid_vat);
    }else{
      setMessages({...messages, helpers: [], successes: [], errors:[]})

    }
    
    
  }
  

  useEffect(()=>{
    if(props?.trial) setButtonText(`${t("Try")} ${currentCart[0]?.name}`)
    else setButtonText(`${t("Buy")} ${currentCart[0]?.name}`)
  },[props?.trial])


  useEffect(() => {
    if (process.env.REACT_APP_SERVER === 'PRODUCTION') {
      if (currentCart[0]?.category === 'dailyprofit') {
        ReactGA.initialize(GA4_QUESTAD);
      } else {
        ReactGA.initialize(GA4_ADSNAP);
      }
    }
  }, [currentCart]);



return (
    <PaymentDetails id="payment-form">
      {loading &&  <Main_Text_Loader text={t('We are proccesing your information. Please do not leave the page.')}/>}
      <CheckoutExtraInput>
          <Builder_ title={t('Country')}>
            <Dropdown_>
                <select defaultValue={'Greece'}  value={props?.selectedCountry} onChange={(e) => props?.setSelectedCountry(e.target.value)}>
                {monicaCountries.map((country, i) => (
                    <option key={i} value={country?.code}>
                    {country?.flag} {country.country}
                    </option>
                ))}
                </select>
              </Dropdown_>
          </Builder_>
          <Checkbox_ checked={props?.showVatInput} className="Invoice" text={t("I wish an invoice")} 
            onClick={handleCheckboxChange}
            />
          {props?.showVatInput ? <Builder_>
            <Input_ 
              placeholder={t("Taxpayer Identification Number (ΤΙΝ)")} 
              onChange={(e)=>checkTin(e.target.value)} 
              helpers={messages?.helpers} 
              successes={messages?.successes} 
              errors={messages?.errors}
              value={vatInput}
            />
          </Builder_> : null}

      </CheckoutExtraInput>
      <PaymentElement options={{defaultValues:{address:{country:props?.selectedCountry}},fields:{name:"never", email:"never", billingDetails:{address:'never'}}}}/>
      <Actions_>
        <Grey_ size="large" text={t("Back")} iconLeft="Back" onClick={()=>navigate(-1)}/>
        <Focus_ size="large" text={buttonText} disabled={!stripe || loading} iconRight="Rocket" onClick={(e) => handleSubmit(e)} />
    </Actions_>
    </PaymentDetails>
  );
}
