import {
  Actions_,
  Red_,
  Builder_,
  CardsSixty,
  Dropdown_,
  Grey_,
  Input_,
  Main_Plain,
  On_Click_Card,
  Section_Title,
  ThemeContext,
  AlertContext,
  Slider_,
  PageTitle,
  device,
} from "monica-alexandria";
import styled from "styled-components";
import React, { useEffect, useState, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Clipboard } from "../PLANS.css";
import {
  delete_plan,
  plan_subscribers,
  send_payment_link,
} from "../../../../services/api/Mocks";
import { Clients_Emails } from "../../createPlan_V3/partials/Clients_Emails";

const SliderCurrentPlan = styled.div``;

const TitleCurrentPlan = styled(PageTitle)`
  display: none;
  @media ${device.xs}{
    display: block;
  }


`;



export default function Slider_Current_Plan(props) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  // useReducer is like useState, but you don't need to use the spread operator e.g. setState({...oldData, newData})
  // instead, you need to write it like this: setState({newData})
  const [langs, setLangs] = useReducer((a, b) => ({ ...a, ...b }), {
    targetLang: "el",
    displayedLang: "el",
  });

  // useState: handle primitives & arrays, useReducer: handle objects
  const [targetEmail, setTargetEmail] = useState();
  const [targetName, setTargetName] = useState();
  const { alerts, setAlerts } = useContext(AlertContext);
  const [emailList, setEmailList] = useState([]);
  const langItems = [
    { text: t("Greek"), value: "el" },
    { text: t("English"), value: "en" },
    // { text: t("German"), value: "de" },
    // { text: t("Italian"), value: "it" },
  ];

  const copyLink = () => {
    navigator.clipboard.writeText(props?.plan?.paymentLink);

    const message = (t("Your link has been copied"));
    const status = "Success";
    alertMessage(message, status);
  };

  const alertMessage = (message, status) => {
    setAlerts([
      ...alerts,
      {
        message: {
          text: t(message),
          status: status,
        },
      },
    ]);
    setTimeout(() => {
      setAlerts([]);
    }, 8000);
  };

  let planPrice = props.plan?.amount/100;
  console.log('planPrice', planPrice);
  
  const sendEmail = () => {
    if (emailList.length > 0) {
      send_payment_link(emailList, langs.targetLang, props?.plan?.paymentLink, planPrice)
        .then((res) => {
          const message = (t("Your email has been sent..."));
          const status = "Success";
          setEmailList([]);
          alertMessage(message, status);
        })
        .catch((err) => {
          const message = (t("There was an error. Please try again later..."));
          const status = "Error";
          alertMessage(message, status);
        });
    }
    else {
      const message = (t("Please add an email address..."));
      const status = "Error";
      alertMessage(message, status);
    }
  };

  const handleLangs = (e) => {
    setLangs({ targetLang: e.target.value, displayedLang: e.target.value });

    let temp = props?.plan?.paymentLink;

    for (let lang of langItems) {
      temp = temp.replace(`/${lang.value}/?`, `/${e.target.value}/?`);
    }
  };

  const deletePlan = () => {
    delete_plan(props.plan)
      .then((res) => {
        const message = (t("Plan deleted"));
        const status = "Success";
        alertMessage(message, status);
      })
      .catch((err) => {
        const message = (t("There was an error. Please try again later..."));
        const status = "Error";
        alertMessage(message, status);
      });
  };


  return (
    <Slider_
        grey={t("Cancel")}
        icon="Check"
        iconGreyLeft="Xicon"
        onClose={props.handleCloseSlider}
        onGrey={props.handleCloseSlider}
        onClick={() => sendEmail()}
        text={t("Send email")}
        title={props.selectedPlan?.name}
        >
    <SliderCurrentPlan>

    {/* <Section_Title text={t("Send link via email")} /> */}
    <TitleCurrentPlan>
      <h3>{props.selectedPlan?.name}</h3>
    </TitleCurrentPlan>

      <Builder_ title={t("Unique plan url")}>
        <Clipboard>
          <p>{props?.plan?.paymentLink}</p>
          <Grey_ iconLeft="Copy" onClick={() => copyLink()} />
        </Clipboard>

      </Builder_>
      <Section_Title text={t("Send link via email")} />

      {/* <Builder_ title={t("Fill in the reciepient's email address")}>
        <Input_
          errors={[t("You must fill in this information")]}
          placeholder={t("Email address")}
          required
          onChange={(e) => setTargetEmail(e.target.value)}
        />
      </Builder_> */}
      <Clients_Emails 
        setEmailList={setEmailList}
        emailList={emailList}  
      />
      {/* <Builder_ title={t("Fill in the reciepient's name (optional)")}>
        <Input_
          placeholder={t("Name")}
          onChange={(e) => setTargetName(e.target.value)}
        />
      </Builder_> */}
      <Builder_ title={t("Select the language you wish the email to be sent")}>
        <Dropdown_
          items={langItems}
          value={langs.targetLang}
          onChange={(e) => handleLangs(e)}
        />
      </Builder_>
      {/* <Actions_ end>
        <Main_Plain
          iconRight="Next"
          onClick={() => sendEmail()}
          text={t("Send email")}
        />
      </Actions_> */}
    </SliderCurrentPlan>
    </Slider_>
  );
}
