import {
  CardsTools,
  Main_,
  On_Click_Card,
  PageFull,
  PageTitle,
  PageTools,
} from "monica-alexandria";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Toolbar_ from "../../partials/navigation/Toolbar_";
import i18n from "../../../localization/i18next";

const clients = require("../../../config/index").config;
const client = process.env.REACT_APP_CLIENT;

const lang = i18n.language ? i18n.language : "el";

export default function PANEL() {

  const navigate = useNavigate();
  const handlenav = (toPath) => {
    navigate(`/${lang}/akihiko/${client}/${toPath}`)
    // window.location.assign(
    //   `${clients[client]}/${lang}/akihiko/${client}/${toPath}`
    // );
  };

  const { t } = useTranslation();

  return (
    <PageFull>
      <PageTitle>
        <h1>{t("Payments' admin panel")}</h1>
      </PageTitle>
      <CardsTools>
        {/* <On_Click_Card
          onClick={() => handlenav("subscribers")}
          icon="User"
          subtitle={t("Subscribers")}
        />
        <On_Click_Card
          onClick={() => handlenav("subscriptions")}
          icon="Payment"
          subtitle={t("Subscriptions")}
        /> */}
        <On_Click_Card
          onClick={() => handlenav("plans-v3")}
          icon="Payment"
          subtitle={t("Pricing plans")}
        />
        <On_Click_Card
          onClick={() => handlenav("translations")}
          icon="Flag"
          subtitle={t("Translations")}
        />
      </CardsTools>
    </PageFull>
  );
}
