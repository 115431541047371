import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../../context/CartContext";
import { Actions_, AlertContext, Alert_, Focus_, Grey_, Main_ ,  Main_Loader, Main_Text_Loader} from "monica-alexandria";
import { create_confirm_payment_intent, create_confirm_subscription } from "../../../services/api/Subscriptions";
import store from "../../../redux/store";
import i18n from "../../../localization/i18next";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";

const clients = require('../../../config/index').config
const PaymentDetails = styled.div``;

export default function Payment_Details(props) {
  const stripe = useStripe();
  const elements = useElements();
  const cart  = useContext(CartContext);
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  const { alerts, setAlerts } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] =  useState(false)
  const [buttonText, setButtonText] = useState("")
  const customer = window.sessionStorage.getItem("customer");
  const user = store.getState().user.user;
  const billing  = JSON.parse(window.sessionStorage.getItem("billing"))
  const currentCart = cart.length>1 ? cart : store.getState().cart
  const lang = i18n.language ? i18n.language : "el";
  const quest = store.getState().params.quest;
  
  const GA4_QUESTAD = process.env.REACT_APP_GOOGLE_ANALYTICS_QUESTAD;
  const GA4_ADSNAP = process.env.REACT_APP_GOOGLE_ANALYTICS_ADSNAP;

  const chooseRedirectURI = (category, property) => {
    switch (true) {
      case category === 'lois':
        return `${clients['lois']}/${lang}/success-payment`;
      case category === 'bond' && property === "vandador":
        return `${clients['vandador']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
      case category === 'bond' && property === "agency":
        return `${clients['agency']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
      case category === 'dailyprofit':
        return `${clients['dailyprofit']}/${lang}/success-payment?quest=${quest}`;
      default:
        return `${clients['griphook']}/${lang}/my-plans`;
    }
  }
  

  const alertMessage = (message, status) => {
    
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
    setTimeout(() => {
      setAlerts((prevAlerts) => prevAlerts.slice(1));
    }, 5000);
  };

  const handleError = (error) => {
    setLoading(false);
    alertMessage(t(`${error.message}`), 'Error');
  }
  const gtag_purchase_event = () =>{
  // Send a custom event
  ReactGA.event("purchase", { 
      value: prettifyPrice(props?.total),
      category: currentCart[0]?.category,
      action: "purchase",
      coupon: props?.discount,
      trial: props?.trail,
      currency: 'EUR'
    });
  }
  const createAndConfirmSubscritpion = (customer, cart, trial, email,  paymentMethod) =>{

    create_confirm_subscription(customer, cart, trial, user?.sub, email, paymentMethod, quest, props?.discount)
    .then(res=>{
      if(res?.code==='SCC4000') {
        setLoading(false);
        alertMessage(t(`${res?.stripe_msg} `), 'Error');
      }
      else {
        setLoading(true);
        handleServerResponse(res?.data)
      } 
    })

  }

  const handleServerResponse = async (response) => {
    
    if (response?.length>0 && response.some(paymentIntent => 
      paymentIntent?.last_payment_error && 
      JSON.stringify(paymentIntent?.last_payment_error) !== "{}"
    )) {
      response.forEach(paymentIntent => {
        alertMessage(t(`${paymentIntent?.last_payment_error?.message} `), 'Error');
      });
      setLoading(false);
    }

    else if (response?.status === "requires_action" ) {
      // Use Stripe.js to handle the required next action
      const {
        error,
        paymentIntent,
        setupIntent
      } = await stripe.handleNextAction({
        clientSecret: response?.client_secret
      })
    
      if (error) {

        setLoading(false);
        alertMessage(t(`${error?.message}`), 'Error')
        // Show error from Stripe.js in payment form
      } 
      else{
        let intent = paymentIntent ? paymentIntent : setupIntent
        createAndConfirmSubscritpion(customer, currentCart, props?.trial, email, intent?.payment_method)
      }
    } 
    else if(response?.status === "succeeded") {
      console.log('redirect to success page')
        createAndConfirmSubscritpion(customer, currentCart, props?.trial, email, response?.payment_method)

    }
    else {
      gtag_purchase_event()
      window.sessionStorage.removeItem('billing');
      window.sessionStorage.removeItem('customer');
      window.location.replace(chooseRedirectURI(currentCart[0]?.category, currentCart[0]?.property))
      window.localStorage.removeItem('cart');
    }

  }

  
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }
    setLoading(true);

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: billing?.name,
          address: billing?.address,
          phone: billing?.phone,
          email: user ? user?.username : email
        }
      }
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
      return;
    }

    // Create the PaymentIntent
    create_confirm_payment_intent(customer, currentCart, props?.trial, user?.sub, paymentMethod?.id, props?.total, props?.currency )
    .then(res => {
      if(res?.code==='SCC4000') {
        setLoading(false);
        alertMessage(t(`${res?.stripe_msg} `), 'Error');
      }
      else handleServerResponse(res?.paymentAction)
    })
    .catch(err => console.log(err))
      
    // Handle any next actions or errors. See the Handle any next actions step for implementation.
  };
  useEffect(()=>{
    if(props?.trial) setButtonText(`${t("Try")} ${currentCart[0]?.name}`)
    else setButtonText(`${t("Buy")} ${currentCart[0]?.name}`)
  },[props?.trial])


  useEffect(() => {
    if (process.env.REACT_APP_SERVER === 'PRODUCTION') {
      if (currentCart[0]?.category === 'dailyprofit') {
        ReactGA.initialize(GA4_QUESTAD);
      } else {
        ReactGA.initialize(GA4_ADSNAP);
      }
    }
  }, [currentCart]);
  
return (
    <PaymentDetails id="payment-form">
      {loading &&  <Main_Text_Loader text={t('We are proccesing your information. Please do not leave the page.')}/>}
      <LinkAuthenticationElement  onChange={(e) => setEmail(e?.value?.email)}/> 
      <PaymentElement/>
      <Actions_>
        <Grey_ text={t("Back")} iconLeft="Back" onClick={()=>navigate(-1)}/>
        {/* <Focus_ text={t("Pay now")} iconRight="Payment" /> */}
        <Focus_ text={buttonText} disabled={!stripe || loading} iconRight="Rocket" onClick={(e) => handleSubmit(e)} />
    </Actions_>
    </PaymentDetails>
  );
}
