import {
    PaymentElement,
    LinkAuthenticationElement,
  } from "@stripe/react-stripe-js";
  import { useContext, useEffect, useState } from "react";
  import { useStripe, useElements, CustomCheckoutProvider } from "@stripe/react-stripe-js";
  import styled from "styled-components";
  import { useTranslation } from "react-i18next";
  import { CartContext } from "../../../context/CartContext";
  import { Actions_, AlertContext, Alert_, Builder_, Checkbox_, Dropdown_, Focus_, Form, Grey_, Input_, Input_Phone_Register, Main_ ,  Main_Loader, Main_Text_Loader, PhoneContext} from "monica-alexandria";
  import { create_confirm_payment_intent, create_confirm_subscription, create_customer, one_step_susbcription_creation } from "../../../services/api/Subscriptions";
  import store from "../../../redux/store";
  import i18n from "../../../localization/i18next";
  import { useNavigate } from "react-router-dom";
  import ReactGA from "react-ga4";
  import { prettifyPrice } from "../../../helpers/prettifyFunctions";
  import { CheckoutExtraInput } from "../../../styles/Layout.css";
  import { checkVAT, countries as jsvatCountries } from 'jsvat';
  import { countries as monicaCountries } from 'monica-alexandria';
import { validatePhone, validatePhoneInternational } from "../../checkoutDetails/utils";
  
  
  const vat_CC = require('../../../config/vatCountryCodes').vatCountryCodes;
  
  const clients = require('../../../config/index').config
  
  const PaymentDetails = styled.div`
  `;


  export default function Payment_Details(props) {
    const stripe = useStripe();
    const elements = useElements();
    const cart  = useContext(CartContext);
    const { t } = useTranslation(); 
    const navigate = useNavigate();
    const { alerts, setAlerts } = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] =  useState(false)
    const [buttonText, setButtonText] = useState("")
    const [vatInput, setVatInput] = useState();
    
    const [phoneCode, setPhoneCode] = useState();
    const [currentPhone, setCurrentPhone] = useState(props?.billingFields?.phone || '');
    const user = store.getState().user.user;

    //TODO: UPDATE CUSTOMER STORAGE LOGIC
    const currentCart = cart.length>1 ? cart : store.getState().cart
    const category = 'dailyprofit';
    const lang = i18n.language ? i18n.language : "el";
    const quest = store.getState().params.quest;
    const GA4_QUESTAD = process.env.REACT_APP_GOOGLE_ANALYTICS_QUESTAD;
    const GA4_ADSNAP = process.env.REACT_APP_GOOGLE_ANALYTICS_ADSNAP;
  
    const chooseRedirectURI = (category, property) => {
      switch (true) {
        case category === 'lois':
          return `${clients['lois']}/${lang}/success-payment`;
        case category === 'bond' && property === "vandador":
          return `${clients['vandador']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
        case category === 'bond' && property === "agency":
          return `${clients['agency']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
        case category === 'dailyprofit':
          return `${clients['dailyprofit']}/${lang}/success-payment?quest=${quest}`;
        default:
          return `${clients['griphook']}/${lang}/my-plans`;
      }
    }
    
    const [messages, setMessages] = useState({
      errorsPhone: [],
      helpersPhone: [],
      successesPhone: [],
      errorsVat: [],
      helpersVat: [],
      successesVat: [],
    });
  
  
    const handleCheckboxChange = () => {
      props?.setShowVatInput(prevState => !prevState);
    };
  
    const alertMessage = (message, status) => {
      
      setAlerts([
          ...alerts,
          {
              message: {
                  text: message,
                  status: status,
              },
          },
      ]);
      setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 5000);
    };

    const validatePhoneFormat = (e) => {
      let formattedPhone = e.target.value.replace(/\s+/g, ''); // Remove any spaces
      let dataPhone = validatePhoneInternational(formattedPhone, t);
      setMessages({
          ...messages,
          helpersPhone: dataPhone.helper,
          successesPhone: dataPhone.success,
          errors:[]
      });
      setCurrentPhone(formattedPhone);
      props.setBillingFields({
          ...props?.billingFields,
          phone: `${phoneCode}${formattedPhone}`
      });
  };

    const validateVat =  (vatValue, country) => {
      let check;
      if(props?.showVatInput) {
  
      // Combine best practices from previous responses:
        const countryCode = vat_CC[country]; // Replace with your actual country code (avoid hardcoding if possible)
        const trimmedVatValue = vatValue.trim(); // Trim leading/trailing whitespace
        // Use optional chaining and nullish coalescing to handle potential undefined values:
       
        const fullVatNumber = trimmedVatValue.startsWith(countryCode)
        ? trimmedVatValue // If it already starts with country code, use as-is
        : `${countryCode}${trimmedVatValue}`; // Otherwise, prepend country code

        check= checkVAT(fullVatNumber, jsvatCountries) || { isValid: false }; // Handle potential undefined check result
        
        if(check?.isValid) {
          props?.setBillingFields({...props?.billingFields, vat:fullVatNumber, address:{country: props?.selectedCountry}})
        }
      }
      else check={isValid:true};
      return check
    };
    
  
    const handleError = (error) => {
      setLoading(false);
      alertMessage(t(`${error.message}`), 'Error');
    }
    

    
    const gtag_purchase_event = () =>{
    // Send a custom event
    ReactGA.event("purchase", { 
        value: props?.total/100,
        category: category,
        action: "purchase",
        coupon: props?.discount,
        trial: props?.trail,
        currency: 'EUR'
      });
    }
  
    const createAndConfirmSubscritpion = (customer, cart, trial, email, paymentMethod) =>{
      // const updatedBilling = window.sessionStorage.getItem('billing')
      console.log("TEST")
      create_confirm_subscription(customer, cart, trial, user?.sub, email, paymentMethod, quest, props?.discount, props?.billingFields, props?.total)
      .then(res=>{
        console.log("RES SUB", res)
        if(res?.code==='SCC4000') {
          setLoading(false);
          alertMessage(t("Something went wrong with stripe. Check your information and try again, or contact support"), 'Error');
        }
        else {
          setLoading(true);
          handleServerResponse(res?.data, customer)
        } 
      })
  
    }
  
    const handleServerResponse = async (response, customer) => {
      setLoading(true);
      console.log("SERVER RESPONSE", response)
      if (response?.length>0 && response.some(paymentIntent => 
        paymentIntent?.last_payment_error && 
        JSON.stringify(paymentIntent?.last_payment_error) !== "{}"
      )) {
        response.forEach(paymentIntent => {
          alertMessage(t("Something went wrong with stripe. Check your information and try again, or contact support."), 'Error');
        });
        setLoading(false);
      }
  
      else if (response?.status === "requires_action" ) {
        // Use Stripe.js to handle the required next action
        const {
          error,
          paymentIntent,
          setupIntent
        } = await stripe.handleNextAction({
          clientSecret: response?.client_secret
        })
      
        if (error) {
  
          setLoading(false);
          alertMessage(t("We could not verify your payment method. Check your information and try again, or contact support."), 'Error')
          // Show error from Stripe.js in payment form
        } 
        else{
          let intent = paymentIntent ? paymentIntent : setupIntent
          createAndConfirmSubscritpion(customer, currentCart, props?.trial, user?.username, intent?.payment_method)
        }
      } 
      else if(response?.status === "succeeded") {
        console.log('redirect to success page')
          createAndConfirmSubscritpion(customer, currentCart, props?.trial, user?.username, response?.payment_method)
      }
      else {
        gtag_purchase_event()
        window.location.replace(chooseRedirectURI(category, currentCart[0]?.property))
      }
  
    }    

    const checkTin = (vatValue) =>{
      setVatInput(vatValue)
      let helpers =[], successes=[];
      console.log('vatValue1', vatValue);
      
      const countryIncluded = jsvatCountries.some(country => country.codes.includes(props?.selectedCountry));

      console.log('countryIncluded && selectedCountry',countryIncluded,props?.selectedCountry, countryIncluded && props?.selectedCountry);
      
      if (countryIncluded && props?.selectedCountry){

       let valid_vat = validateVat(vatValue, props?.selectedCountry)
       if (!valid_vat?.isValid) helpers.push(t('TIN is not valid'))
       if (valid_vat?.isValid) successes.push(t('TIN is valid'))
        

        setMessages({...messages, helpersVat: helpers, successesVat: successes})
        console.log('here',valid_vat);
      }else{
        setMessages({...messages, helpersVat: [], successesVat: [], errorsVat:[]})

      }
      
      
    }
    
    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return null;
      }
  
      // Trigger form validation and wallet collection
      const {error: submitError} = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }
  
      // Create the PaymentMethod using the details collected by the Payment Element
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        elements,
        params:{billing_details: {
          address: {country: props?.selectedCountry, postal_code :"", state:"", city:"", line1:"", line2:""}
          }
        }
      });
      console.log("paymentMethod", paymentMethod)

     console.log(messages);
     
     if (messages?.helpersPhone?.length > 0 || !currentPhone || (props.showVatInput && (messages?.helpersVat?.length > 0 || !vatInput) ) ) {
      // Handle VAT input validation
      console.log('props.showVatInputprops.showVatInput', props.showVatInput);
      
      if (props.showVatInput) {
        console.log('Hi');
        
        if (!vatInput) {
          setMessages((prev) => ({ ...prev, errorsVat: ['VAT input cannot be empty'],helpersVat: [],successesVat: []}));
        } else if (messages?.helpersVat?.length > 0) {
          setMessages((prev) => ({...prev, errorsVat: prev.helpersVat || [] ,helpersVat: [],successesVat: []}));
          setVatInput('');
        }
      
      }
    
      // Handle phone input validation
      if (!currentPhone) {
        setMessages((prev) => ({...prev,errorsPhone: ['Phone input cannot be empty'],helpersPhone: [],successesPhone: []}));
      } else if (messages?.helpersPhone?.length > 0) {
        setMessages((prev) => ({...prev,errorsPhone: prev.helpersPhone || [], helpersPhone: [], successesPhone: []}));
        setCurrentPhone('');   
    }

    
      // Alert message for any error
      alertMessage(t(`Something went wrong. Please check your fields.`), 'Error');
      return;
    }
      else if (error) {
        // This point is only reached if there's an immediate error when
        // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
        handleError(error);
        return;
      }
      // Create the PaymentIntent
      else {
        setLoading(true);
        create_customer(props?.billingFields, user?.username, user?.sub, category)
        .then(res => {
          console.log("RES CUS", res)

            if(res?.code==='SCC4000') {
                alertMessage(t(`Something went wrong. Check you information and try again.`), 'Error');
            }
            else{
              create_confirm_payment_intent(res?.customer, currentCart, props?.trial, user?.sub, paymentMethod?.id, props?.total, props?.currency)
              .then(intent => {
                console.log("RES intent", intent)

                if(intent?.code==='SCC4000') {
                  setLoading(false);
                  alertMessage(t("Your card was declined. Make sure you have enough funds to complete the payment."), 'Error');
                }
                else handleServerResponse(intent?.paymentAction, res?.customer)
              })
              .catch(err => console.log(err))
            }
        })
        .catch(err => console.log(err))        
        
    
      // Handle any next actions or errors. See the Handle any next actions step for implementation.
      }
    };
  

    useEffect(()=>{
      if(props?.trial) setButtonText(`${t("Try")} ${currentCart[0]?.name}`)
      else setButtonText(`${t("Buy")} Questad`)
    },[props?.trial])
  
  
    useEffect(() => {
      if (process.env.REACT_APP_SERVER === 'PRODUCTION') {
        if (category === 'dailyprofit') {
          ReactGA.initialize(GA4_QUESTAD);
        } else {
          ReactGA.initialize(GA4_ADSNAP);
        }
      }
    }, [currentCart]);
  
    useEffect(() => {
      if (vatInput) {
        checkTin(vatInput); // This will run checkTin whenever selectedCountry changes
      }
    }, [props?.selectedCountry, vatInput]);
    
  /* TODO: AD LOADER FOR PAGE INIT*/

  console.log('billing_details', props?.billingFields);

    console.log('MESSAGES', messages);
    
  return (
      <PaymentDetails id="payment-form">
        {loading &&  <Main_Text_Loader text={t('We are proccesing your information. Please do not leave the page.')}/> /* THIS LOADER SHOULD APPEAR ONLY WHEN STRIPE PROCCESES THE PAYMENT INFO*/} 
        
        <CheckoutExtraInput>
            <PhoneContext.Provider value={{ phoneCode, setPhoneCode }}>
              <Builder_ title={t('Fill in your phone number')} >
                  <Input_Phone_Register
                      placeholder={t('Phone number')}
                      listIcon="Phone"
                      errors={messages.errorsPhone}
                      helpers={messages.helpersPhone}
                      successes={messages.successesPhone}
                      onChange={validatePhoneFormat}
                      value={currentPhone}
                      required
                  />
              </Builder_>
            </PhoneContext.Provider>
            <Builder_ title={t('Country')}>
              <Dropdown_>
                  <select defaultValue={'Greece'}  value={props?.selectedCountry} onChange={(e) => props?.setSelectedCountry(e.target.value)}>
                  {monicaCountries.map((country, i) => (
                      <option key={i} value={country?.code}>
                      {country?.flag} {country.country}
                      </option>
                  ))}
                  </select>
              </Dropdown_>
            </Builder_>
            <Checkbox_
              checked={props?.showVatInput} 
              className="Invoice" 
              text={t("I wish an invoice")} 
              onClick={handleCheckboxChange}
              id='invoiceVat'
            />
          
            {props?.showVatInput ? <Builder_>
              <Input_ 
                placeholder={t("Taxpayer Identification Number (ΤΙΝ)")} 
                onChange={(e)=>checkTin(e.target.value)} 
                helpers={messages?.helpersVat} 
                successes={messages?.successesVat} 
                errors={messages?.errorsVat} 
                value={vatInput}
                required
              />
            </Builder_> : null}
           
        </CheckoutExtraInput>
        <PaymentElement options={{defaultValues:{address:{country:props?.selectedCountry}},fields:{name:"never", email:"never", billingDetails:{address:'never'}}}}/>
        <Actions_>
          <Grey_ size="large" text={t("Back")} iconLeft="Back" onClick={()=>navigate(-1)}/>
          <Focus_ size="large" text={buttonText} disabled={!stripe || loading} iconRight="Rocket" onClick={(e) => handleSubmit(e)} />
      </Actions_>
      </PaymentDetails>
    );
  }
  