import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { prettifyDates } from '../../helpers/prettifiers'
import { prettifyPrice } from '../../helpers/prettifyFunctions'
import i18n from '../../localization/i18next'

const Summary = styled.div`
    padding: 2rem;
    box-shadow: ${p => p.theme.out};
    background: ${p => p.theme.low};
    border-radius: var(--smallRadius);
`

const SummaryItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const SummarySep = styled.div`
    margin: 1rem 0;
    height: 2px;
    width: 100%;
    background: ${p => p.theme.mid};
`

const SummaryText = styled.div`
    padding: 1rem;
    //border-radius: var(--smallRadius);
    //border: 1px dashed var(--grey);
    background: ${p => p.theme.mid};
    margin: 2rem 0;
    border-radius: 0.2rem;
    border-left: 4px solid var(--focus);
   // border: 2px dashed var(--greyDark);
        transition-property: background, border;
        transition-duration: .5s;
      //  border-radius: var(--smallRadius);
`

const SummaryPrice = styled.div`
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
`

export default function Summary_(props) {

    const {t} = useTranslation();
    const lang = i18n.language ? i18n.language : "el";


    const paid_at_date = () => {
        let date = props?.trial ? Date.now() + parseInt((props?.discount?.couponInfo?.trialDays) * 24 * 60 * 60 * 1000) : Date.now();
        return prettifyDates(date, false, lang); // Pass false to indicate it's not a Unix timestamp
    }
    

     function prettifyCurrency(currency) {
        if (currency == "eur") return "€";
        else return currency;
    }

  return (
    <Summary>
        <SummaryItem>
        <h3>{t("Price breakdown")}</h3>
            {/* {props?.trial ? <h2>{prettifyCurrency('eur')} 0,00</h2> : null} */}
        </SummaryItem>
        <SummarySep></SummarySep>
        <SummaryPrice>
            <p>{t("Price")}</p>
            <h5>{prettifyCurrency(props?.currency)} {prettifyPrice(props?.amount)}</h5>
        </SummaryPrice>
        <SummaryPrice>
            <p>{t("Tax")}</p>
            <h5>{prettifyCurrency(props?.currency)} {prettifyPrice(props?.tax)}</h5>
        </SummaryPrice>
        <SummarySep></SummarySep>
        <SummaryItem>
            <div>
                <h4>{t("Payable at")}:</h4>
                <h6>{paid_at_date()}</h6>
            </div>
            <h2>{prettifyCurrency(props?.currency)} {props?.tax ? prettifyPrice(props?.total) : prettifyPrice(props?.total)}</h2>
        </SummaryItem>
        {props?.trial ? <SummaryText>
            <h5>{t("You will not be billed today")}</h5>
            <p>{t("Your card will be charged")}{" "}
            {prettifyCurrency(props?.currency)} 
            {t("9,90 on")}{" "}
            {paid_at_date()}.
            </p>
        </SummaryText> : null}
        {props?.trial ? <SummaryText>
            <h5>{t("Cancellation conditions")}</h5>
            <p>{t("You can cancel your trial and auto-renewal BEFORE")} {paid_at_date()}. {t("Your subscription will stop immediately, and you will not be charged.")}</p>
        </SummaryText> : null}
        <SummaryText>
            <h5>{t("Billing cycle")}</h5>
            <p>{t("Your subscription is based on a 30-day interval. This means that your payment method will be automatically charged every 30 days starting from")} {paid_at_date()}.</p>
        </SummaryText>
    </Summary>
  )
}
