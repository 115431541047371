import { PageSixty, PageAlwaysVisible, PageTitle, ThemeContext, logo, Section_Title, Main_Loader, PageSubtitle, Partial_Loader, P, Main_, Grey_Link} from 'monica-alexandria';
import React, {useContext, useEffect, useState } from "react";
import { prettifyDates } from "../../helpers/prettifiers";
import store from "../../redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import { getTransactions, getInvoicesByQuestId } from "../../services/api/Transactions";
import { create_portal_session, get_my_subscriptions } from "../../services/api/Mocks";
import i18n from "../../localization/i18next";
import { useTranslation } from "react-i18next";
import { prettifyPrice } from "../../helpers/prettifyFunctions";
import currencies from '../../data/currencies.json';
import { Helmet } from "react-helmet";
import Subscription_Plan from "../../components/subscription/Subscription_Plan";
import Subscription_History_Block from "../../components/subscription/Subscription_History_Block";
import styled from 'styled-components';
import { getQuestsWithSubIds } from '../../services/api/Quests';

const DailyprofitPlan = styled.div`
`;

const DailyprofitQuestWrapper = styled.div`
`;
export default function DAILYPROFIT_PLAN() {

    const protocol = process.env.REACT_APP_DAILYPROFIT_FRONTEND_PROTOCOL;
    const url = process.env.REACT_APP_DAILYPROFIT_FRONTEND_HOST;
    const port = process.env.REACT_APP_DAILYPROFIT_FRONTEND_PORT;
    const dailyprofitURL = `${protocol}${url}${port}`;

    const user = store.getState().user.user;
    const userId = user.sub;
    const { t } = useTranslation();
    const location = useLocation();
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();

    const lang = i18n.language ? i18n.language : 'en';
    const [isLoading, setIsLoading] = useState(false);

    const redirectURL = `${window.location.origin}${window.location.pathname}?name=Adsnap&category=lois`;
    const [ initialLoading, setInitialLoading ] = useState(true);

    const [activeQuests, setActiveQuests] = useState([]);
    const [archivedQuests, setArchivedQuests] = useState([]);
    const [activeQuest, setActiveQuest] = useState(null);
    const [invoices, setInvoices] = useState([]);

    const [loadingInvoices, setLoadingInvoices] = useState(false);
    
    const go_to_edit_subscription = (customer) => {
  
      let payload = {
        customer,
        lang:lang,
        userId,
        redirectURL
      }
  
      create_portal_session(payload)
        .then(data => {
          if (data?.data?.url) {
            setIsLoading(false)
            window.location.assign(data?.data?.url)
          }
          else isLoading(true);
        })
        .catch(err => {})
    }

    const fetchMyQuests = () => {
      getQuestsWithSubIds(userId)
      .then(res => {
        console.log('data', res);
        const myQuests = res?.data?.data;
        setActiveQuests(myQuests?.filter((quest) => quest.active == true));
        setArchivedQuests(myQuests?.filter((quest) => quest.active == false));

        setInitialLoading(false);

      })
      .catch(err => {});
    }

    useEffect(() => {
      fetchMyQuests();
    }, []);

    useEffect(() => {
      // Init activeQuest in order to init invoices
      if(activeQuests?.length > 0) {
        setActiveQuest(activeQuests[0])
      }
      else if(archivedQuests?.length > 0) {
        setActiveQuest(archivedQuests[0])
      }
    }, [activeQuests, archivedQuests]);

    useEffect(() => {
      if(activeQuest) {
        console.log('getting invoiced for: ', activeQuest);
        setInvoices([]);
        setLoadingInvoices(true);
        getInvoicesByQuestId(activeQuest?.matchingSubId?.customer, activeQuest?._id)
        .then((res) => { 
          console.log(res);
          setInvoices(res?.data);
          setLoadingInvoices(false);
        })
        .catch((error) => console.log(error))
      }

    }, [activeQuest]);
  
      console.log('activeQuests: ', activeQuests);
      console.log('archivedQuests: ', archivedQuests);
      console.log('activeQuest: ', activeQuest);
      console.log('invoices: ', invoices);
      
      
  return (
    <DailyprofitPlan>
        <Helmet>
          <title>{`Doitforme | Questad subscription`}</title>
        </Helmet>

        { initialLoading ? <Main_Loader /> : 
        <PageSixty>
            <PageAlwaysVisible>
              <PageTitle>
                <Grey_Link noPadding iconLeft="Back" onClick={() => navigate(-1)} />
                <h1>{"Questad"} {t("subscription")}</h1>
              </PageTitle>
              {activeQuests?.length > 0 && 
              <DailyprofitQuestWrapper>
                <Section_Title text={t("Active quests")}/>
                {activeQuests?.map((quest) => {
                  let daysLeft = Math.ceil((quest?.matchingSubId?.current_period_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24));
                  return (
                    <Subscription_Plan 
                      title={t("Quest")}
                      category="dailyprofit"
                      currentPlan={quest?.questionnaires[1].answer + " | " +  quest?.questionnaires[0].answer}
                      img={logo["dailyprofit"][theme]}
                      currency= {currencies[quest?.matchingSubId?.currency?.toUpperCase()]?.symbol || currencies["EUR"]?.symbol}
                     //price= {prettifyPrice(quest?.matchingSubId?.amount)}
                      price= {prettifyPrice(quest?.amountPaid)}
                      daysLeft={daysLeft}
                      nextPayment={prettifyDates(quest?.matchingSubId?.current_period_end, true, lang)}
                      onClick={() => setActiveQuest(quest)}
                      dailyprofitDashboardUrl={`${dailyprofitURL}/${lang}/dashboard/subscriptions`}
                      questId={quest?._id}
                      taxBehavior="inclusive"
                      isActive={quest?.active}
                      newBudget={quest?.newBudget ? prettifyPrice(quest?.newBudget) : false}
                    />
                  )
                })}
              </DailyprofitQuestWrapper>}
              {archivedQuests?.length > 0 && 
              <DailyprofitQuestWrapper>
                <Section_Title text={t("Archived quests")}/>
                {archivedQuests?.map((quest) => {
                  let daysLeft = Math.ceil((quest?.matchingSubId?.current_period_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24));

                  return (
                    <Subscription_Plan 
                      title={t("Quest")}
                      category="dailyprofit"
                      currentPlan={quest?.questionnaires[1].answer + " | " +  quest?.questionnaires[0].answer}
                      img={logo["dailyprofit"][theme]}
                      currency= {currencies[quest?.currency?.toUpperCase()]?.symbol || currencies["EUR"]?.symbol}
                      price= {prettifyPrice(quest?.matchingSubId?.amount)}
                      daysLeft={daysLeft}
                      onClick={() => setActiveQuest(quest)}
                      taxBehavior="inclusive"
                      isActive={quest?.active}

                      // dailyprofitDashboardUrl={`${dailyprofitURL}/${lang}/dashboard/subscriptions`}
                      // questId={quest?._id}
                    />
                  )
                })}
              </DailyprofitQuestWrapper>}
            </PageAlwaysVisible>
            <PageAlwaysVisible>
              <Section_Title text={t('Payment History')} />
                {loadingInvoices ? <Partial_Loader /> : null}
                {invoices?.length > 0 && activeQuest &&
                  invoices?.map((invoice, i) => {
                    return (
                      <Subscription_History_Block
                        key={i}
                        currency={currencies[invoice?.currency.toUpperCase()]?.symbol}
                        price={prettifyPrice(invoice?.total)}
                        status={invoice?.status}
                        date={prettifyDates(invoice?.created, true)}
                        invoicePdf={invoice.invoice_pdf}
                      />
                    );
                  })}
            </PageAlwaysVisible>
        </PageSixty> }
    </DailyprofitPlan>
  )
}
