import React, { useContext, useEffect, useState } from "react";
import { Checkout } from '../../styles/Layout.css'
import { Builder_, PageAlwaysVisible, PageTitle, AlertContext, Alert_, ThemeContext, Single_, Form, PageDesktopPadding, device, } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'
import Summary_ from '../../components/checkout/Summary_'
import styled from 'styled-components'
import Lois_Checkout from './components/Lois_Checkout';
import Payment_Details from './components/Payment_Details'
import store from "../../redux/store";
import i18n from "../../localization/i18next";
import { Elements, ElementsConsumer, LinkAuthenticationElement, PaymentMethodMessagingElement } from "@stripe/react-stripe-js";
import { calculate_tax, calculate_tax_manual } from "../../services/api/Subscriptions";
import { CartContext } from "../../context/CartContext";
import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet";
 
const CheckoutGrid = styled.div`
    display: grid;
    grid-template-columns: 30rem 1fr;
    grid-gap: 4rem;
    align-items: flex-start;
 
    @media ${device.xl} {
      grid-template-columns: 1fr;
    }
`
export default function LOIS_CHECKOUT_V3() {
 
    const { alerts, setAlerts } = useContext(AlertContext);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);
    const { cart } = useContext(CartContext);
    const { t } = useTranslation();
    const lang = i18n.language ? i18n.language : "el";
    const { theme } = useContext(ThemeContext);
    const [invoice, setInvoice] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCountry, setSelectedcountry] = useState('GR')
    const [showVatInput, setShowVatInput] = useState(true);
 
    const customer = window.sessionStorage.getItem("customer");
    const user = store.getState().user.user;
    const currentCart = cart?.length>0 ? cart : store.getState().cart
    const coupon = window?.sessionStorage?.getItem('coupon')
    const trial = window?.sessionStorage?.getItem('trial')
 
    const handleStripeTheme = () => {
        let stripeTheme = theme == "dark" ? "night" : "stripe";
        return stripeTheme;
    }
    ;
    const options = {
        mode: "subscription",
        amount: invoice?.totalAmount || 0,
        currency: invoice?.currency || 'eur',
        // automatic_payment_methods: {enabled: true},
        paymentMethodCreation: 'manual',
        locale: lang || 'el',
        appearance: {
          theme: handleStripeTheme(),
          labels: "floating",
          variables: {
            fontSizeBase: "1.6",
            spacingUnit: "4",
            borderRadius: "1rem",
          },
        },
      };
 
      const calculate_invoice_amount = () => {
        calculate_tax_manual(selectedCountry, coupon?.couponId, currentCart[0]?.amount, showVatInput)
            .then(res => {
                console.log("res tax", res);
                setInvoice(res?.taxCalculation);
                setIsLoading(false);
            })
            .catch(err => {
                console.log('ERROR',err);
                setIsLoading(false);
            });
    };
 
      useEffect(()=>{
        if(currentCart?.length > 0 && selectedCountry) {
          calculate_invoice_amount()
        }
      },[cart, coupon, selectedCountry, showVatInput])
      console.log('customer', customer)
      console.log('invoice', invoice)
      console.log("selectedCountry", selectedCountry)
      console.log("currentCart[0]?.amount", currentCart[0]?.amount)
      console.log("showVatInput", showVatInput)
    if(isLoading){ return(
        <Single_
         backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/griphook/ToStripe.svg"
         title={t("Checkout")}
         subtitle={t("Redirecting you to Stripe")}
         text={t("Hold on tight, while we teleport you to the magical land of Stripe where payments are as smooth as butter and the unicorns roam free!")}
       />
    );
    } 
    else return (
        <Checkout>
        <Helmet>
          <title>Doitforme | Adsnap subscribe</title>
        </Helmet>
        <Alert_ messages={alerts} duration={5000}/>
            <PageAlwaysVisible>
                <PageTitle>
                    <h1>{t("Checkout")}</h1>
                </PageTitle>
                <CheckoutGrid>
                    <Lois_Checkout />
                    <Summary_ trial={trial==='true' ? true : false} discount={JSON.parse(coupon)} amount ={currentCart[0]?.amount} tax={invoice?.taxAmount} total={invoice?.totalAmount} currency={invoice?.currency || '€'}/>
                </CheckoutGrid>
            </PageAlwaysVisible>
            <PageAlwaysVisible>
              <PageDesktopPadding>
                {options &&  
                  <Form>
                    <Elements stripe={stripePromise} options={options}>
                      <h3>{t("Billing information")}</h3>
                      <Payment_Details discount={JSON.parse(coupon)?.couponId} selectedCountry={selectedCountry} setSelectedCountry={setSelectedcountry} setShowVatInput={setShowVatInput} showVatInput={showVatInput} trial={trial==='true' ? true : false} total={invoice?.totalAmount} currency={invoice?.currency || '€'}/>
                    </Elements> 
                  </Form>
                  }
                </PageDesktopPadding>
            </PageAlwaysVisible>
        </Checkout>
  )
}