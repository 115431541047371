import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AlertContext, Form, PageAlwaysVisible, PageDesktopOnly, PageForty, PageTitle, Builder_, Input_, Checkbox_, Radio_, IconSvg, Grey_, Main_, Main_Plain, Actions_, Red_, Focus_Plain, Alert_, Grey_Link } from "monica-alexandria";
import { Tabs_ } from "../../../library/buttons/Tabs_";
import { Dropdown_Button } from "../../../library/buttons/Dropdown_Button";
import { Advanced_Options } from "./partials/Advanced_Options";
import { create_plan } from "../../../services/api/Plans";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";
import { Clients_Emails } from "./partials/Clients_Emails";
import { send_payment_link } from "../../../services/api/Mocks";

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


const BudgetFee = styled.div`
    /* border: 1px dashed var(--grey); */
    border-radius: 0.5rem;
    background: ${(p)=> p.theme.mid};
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`
const ValuesWrapper = styled.div`
    display: grid ;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1rem;
    padding-top: 1rem;
`
const PlanPrice = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: baseline;
  
`
export default function CREATE_PLAN_V3(props) {
    const {t} = useTranslation();
    const {alerts, setAlerts} = useContext(AlertContext);
    const {lang} = useParams();
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = useState('recurring');
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false); // State to control the visibility of advanced settings

    const [recurring, setRecurring] = useState({interval: "day", interval_count: 30})
    const [emailList, setEmailList] = useState([])
    const [amount, setAmount] = useState(100)

    const [messages, setMessages] = useState('')

    const transform_price_to_units = (price) => {
        // Replace commas with periods to standardize the input
        let standardizedPrice = price.replace(",", ".");
    
        // Parse the standardized string as a float
        let floatPrice = parseFloat(standardizedPrice);
    
        if (isNaN(floatPrice)) {
            return 0; // Return 0 or handle the error as needed
        }
    
        // Truncate to two decimal places (ignore the third decimal)
        let truncatedPrice = Math.floor(floatPrice * 100) / 100;
    
        // Convert the truncated value to cents (multiply by 100)
        let finalPriceInCents = Math.round(truncatedPrice * 100);
    
        // Return the final integer value for Stripe
        return finalPriceInCents;
    };
    

    const alertMessage = (message, status) => {
        setAlerts(prevAlerts => [
            ...prevAlerts,   // Ensure previous alerts are retained
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };
    //
    const new_plan = async() =>{
        const planPrice = amount / 100;
        create_plan(selectedType, amount, recurring)
        .then(res =>{
            console.log("res price data > ", res)
            if(res?.data?.code === "P2001") {                        
                alertMessage('Plan already exists!', 'Error' )
            }
            else if (res?.data?.code === "COUT2000") {   
                const paymentLink = res?.data?.data?.paymentLink;
                alertMessage( t('Plan created successfully!'), 'Success' )
                if (emailList?.length > 0){
                         send_payment_link(emailList, lang, paymentLink, planPrice)
                        .then(paymentRes => {
                            console.log("Payment link sent successfully:", paymentRes);
                            alertMessage( t('Payment link sent successfully!'), 'Success' )
                        
                            // setAlerts([...alerts, { type: 'success', message: t('Payment link sent successfully!') }]);
                        })
                        .catch(paymentErr => {
                            console.error("Failed to send payment link:", paymentErr);
                            alertMessage( t('Failed to send payment link.'), 'Error' )
                            // setAlerts([...alerts, { type: 'error', message: t('Failed to send payment link.') }]);
                        });
                }
                 navigate(`/${lang}/akihiko/griphook/plans-v3/`)
            }
            else  alertMessage(t('Something went wrong'), 'Error' )
        })
        .catch(err => console.log("err", err))
    }


    const checkAmount = (value)=> {
        let helpers = [];
        let tranformedAmount = transform_price_to_units(value) 
        if (isNaN(tranformedAmount) || tranformedAmount <= 0) {
            helpers.push(t('Please enter a valid number.'));
        }
        
        setAmount(tranformedAmount)
        setMessages(helpers)
    }

    const checkCreatePlan = () => {
       //const helpers = messages.helpers;
        if (messages?.helpers?.length > 0){
            alertMessage( messages?.helpers, 'Error' )
        } 
        else {
            new_plan();
        }
    }    

    const netValue = ((amount / 100) / 1.24).toFixed(2);
    const taxAmount = ((amount / 100) - netValue).toFixed(2);
    console.log("ammount", amount)
    console.log(" parsed ammount", parseFloat(amount))

    return(
        <PageForty>
        <Alert_/>
            <PageAlwaysVisible>
                <PageTitle cta>
                    <h1>{t("Create Plan")}</h1>
                </PageTitle>
                <Form>
                    
                    <Builder_>
                        <TabsWrapper>
                            <Tabs_ selectedType={selectedType} handleType={(type) => setSelectedType(type)}/>
                        </TabsWrapper>
                    </Builder_>
                    <Builder_ title={t('Fill in the plan price including tax')}>
                        <PlanPrice> 
                            <Input_ 
                                placeholder={t("Amount")}
                                defaultValue={prettifyPrice(amount)} 
                                onChange={(e)=> {checkAmount(e.target.value)}}
                                helpers={messages}
                            />
                            {selectedType === 'recurring' ? <h6> / {recurring?.interval_count === 30 ? '30 days' : recurring?.interval}</h6> : null}
                        </PlanPrice>
                        <ValuesWrapper>
                            <BudgetFee>
                                <p>{t('Net value')}</p>
                                <h5>{amount > 0 ? netValue : "-"}</h5>
                            </BudgetFee>
                            <BudgetFee>
                                <p>{t('Tax')}</p>
                                <h5>{amount > 0 ? taxAmount : "-"}</h5>
                            </BudgetFee>
                        </ValuesWrapper>
                    </Builder_>
                    {selectedType === "recurring" && (
                        <Builder_>   
                            <Grey_Link noPadding iconLeft="Settings" text={t("Change interval")} onClick={() => setShowAdvancedSettings(!showAdvancedSettings)} />
                            {showAdvancedSettings && (
                                <Advanced_Options 
                                    recurring={recurring}
                                    setRecurring={setRecurring}
                                />
                            )} 
                        </Builder_>
                    )}
                    <Clients_Emails
                        setEmailList={setEmailList}
                        emailList={emailList}  
                    />
                    
                    <Actions_>
                        <Grey_ iconRight="Xicon" text={t("Cancel")} onClick={() => navigate(`/${lang}/akihiko/griphook`)}/>
                        <Main_ 
                            iconRight="Next" 
                            text={t("Create Plan")} 
                            onClick={() => checkCreatePlan()}
                            />
                    </Actions_>
                </Form>
            </PageAlwaysVisible>
            <PageDesktopOnly>
                {/* Additional desktop only content could go here */}
            </PageDesktopOnly>
        </PageForty>
    )
}
