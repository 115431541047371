import { PageSixty } from "monica-alexandria";
import styled from "styled-components";

export const Subscribe = styled(PageSixty)``

export const Checkout = styled(PageSixty)``

export const CheckoutExtraInput = styled.div`
  border-bottom: 1px dashed var(--greyDark);
  margin-bottom: 2rem;

 // margin-top: 1.5rem;

  /* input{
    background: var(--light);
    border: 1px solid var(--greyLight);
    color: var(--black) !important;
    border-radius: 16px;
  } */

  .Invoice{
    margin-bottom: .5rem;

    /* input{
      background: var(--light) !important;
      color: var(--black) !important;
    }
     */
  }
`