import React, { useContext, useEffect, useState } from "react";
import { Checkout } from '../../styles/Layout.css'
import { Builder_, PageAlwaysVisible, PageTitle, AlertContext, Alert_, ThemeContext, Single_, Form, PageDesktopPadding, device, PageFull, } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'
import Summary_ from './components/Summary_';
import styled from 'styled-components'
import Lois_Checkout from '../lois/components/Lois_Checkout';
import Payment_Details from './components/Payment_Details'
import store from "../../redux/store";
import i18n from "../../localization/i18next";
import { Elements, LinkAuthenticationElement } from "@stripe/react-stripe-js";
import { calculate_tax, calculate_tax_manual, get_customer } from "../../services/api/Subscriptions";
import { CartContext } from "../../context/CartContext";
import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet";
import Dailyprofit_Checkout from "./components/Dailyprofit_Checkout";
import { getProductswithCategoryAsInput } from "../../services/api/Plans";
import { getAmountFromQuest } from "../../services/api/Quests";
import q from 'query-string'


const OnePageCheckout = styled(PageFull)`

`
const CheckoutGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-gap: 4rem;
    align-items: flex-start;

    @media ${device.xl} {
      grid-template-columns: 1fr;
    }
`

const CheckoutPaymentDetails = styled(Form)`
  border-radius:var(--smallRadius);

`;
export default function LOIS_CHECKOUT_V3() {

    const { alerts, setAlerts } = useContext(AlertContext);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);
    const { cart, setCart } = useContext(CartContext);
    const { t } = useTranslation();
    const lang = i18n.language ? i18n.language : "el";
    const { theme } = useContext(ThemeContext);
    const [tax, setTax] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isProductLoading, setIsProductLoading] = useState(true)
    const [isCartLoading, setIsCartLoading] = useState(true)
    const [customerIsLoading, setCustomerIsLoading] = useState(true);

    const user = store.getState().user.user;
    const currentCart = cart?.length>0 ? cart : store.getState().cart
    const coupon = window?.sessionStorage?.getItem('coupon')
    const trial = window?.sessionStorage?.getItem('trial')
    const [customer, setCustomer] = useState(false);

    const [selectedOption, setSelectedOption] = useState('month'); // 'year' or 'month'
    const [defaultItem, setDefaultItem] = useState(false);
  
    const [billingFields, setBillingFields] = useState({});
    const [selectedCountry, setSelectedCountry] = useState('GR');
    const [selectedAmount, setSelectedAmount] = useState(false);
    const [showVatInput, setShowVatInput] = useState(true);

    const queryParams = q.parse(window.location.search);

    const check_if_customer_exists = () => {
      get_customer(user?.sub, 'dailyprofit')
        .then(res => {

          const billingData = {
            name: res?.customer?.name|| `${user?.firstname} ${user?.lastname}`,
            email: res?.customer?.email || user?.username,
            phone: res?.customer?.phone || '',
            address : res?.customer?.address || {}
          };
          setSelectedCountry(res?.customer?.address?.country || 'GR')
          console.log('CUSTOMER', res)
          if(res?.customer?.customerId) setCustomer(res?.customer?.customerId)

          setBillingFields(billingData);

          setCustomerIsLoading(false);
        })
        .catch(err => console.log(err));
    }
  
  // Function to initialize cart and set default item
  const initializeCartAndDefaultItem = (res, query) => {
    sessionStorage.removeItem('cart');
    setCart([])
    setDefaultItem(res[0]);

    getAmountFromQuest(query?.quest)
    .then(res => {
      let amount = res?.quest?.questionnaires[res?.quest?.questionnaires?.length - 1];
      setSelectedAmount(parseInt(amount?.answer*100))
      setIsProductLoading(false);
    })
    .catch(err=> console.log(err))

  };

  const calculate_invoice_amount = () => {
    calculate_tax_manual(selectedCountry, coupon?.couponId, selectedAmount, showVatInput)
        .then(res => {
            console.log("res tax", res);
            setTax(res?.taxCalculation);
            setIsLoading(false);
        })
        .catch(err => {
            console.log(err);
            setIsLoading(false);
        });
};

  useEffect(() => {
    console.log('queryParams',queryParams )
      // Fetch products only once and call the function to initialize cart and set default item
    let query = JSON.stringify(queryParams) !== '{}' ? queryParams : store.getState().params
    store.dispatch({type:'SAVE_CART_PARAMS', payload: query}); 

    getProductswithCategoryAsInput(query, selectedOption)
    .then(res => initializeCartAndDefaultItem(res, query))
    .catch((err) => console.log(err));

  }, [selectedOption]);

  // Update sessionStorage whenever the cart changes
  useEffect(() => {
    // Only set the default item to cart if it's not already in the cart
    if (defaultItem && selectedAmount && !cart.some((item) => item.priceId === defaultItem.priceId)) {
      console.log('def', defaultItem)
      defaultItem.amount = selectedAmount
      delete defaultItem.priceId;
      setCart((prevCart) => [...prevCart, defaultItem]); // Use callback form to ensure up-to-date cart
      setIsCartLoading(false)
    }        
    store.dispatch({type:'SAVE_CART', payload: cart});

  }, [selectedAmount, defaultItem, cart]);
  

    const handleStripeTheme = () => {
        let stripeTheme = theme == "dark" ? "night" : "stripe";
        return stripeTheme;
    }
    ;
    const options = {
        mode: "subscription",
        amount: tax?.totalAmount || 50,
        currency: tax?.currency || 'eur',
        // automatic_payment_methods: {enabled: true},
        paymentMethodCreation: 'manual',
        locale: lang || 'el',
        appearance: {
          theme: handleStripeTheme(),
          labels: "floating",
          variables: {
            fontSizeBase: "1.6",
            spacingUnit: "4",
            borderRadius: "1rem",
          },
        },
      };
      
      useEffect(() => {
        if (selectedAmount && selectedCountry) {
            calculate_invoice_amount();
        }
    }, [selectedAmount, coupon, selectedCountry, showVatInput]);
    
    useEffect(() => {
        if (!customer) {
            check_if_customer_exists();
        }
    }, []);
    

      console.log('tax', tax)
      console.log('amount', selectedAmount)

      console.log('cart', cart)
      console.log("country", selectedCountry)

    if(isLoading && customerIsLoading && isProductLoading && isCartLoading){ return(
        <Single_
         backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/griphook/ToStripe.svg"
         title={t("Checkout")}
         subtitle={t("Redirecting you to Stripe")}
         text={t("Hold on tight, while we teleport you to the magical land of Stripe where payments are as smooth as butter and the unicorns roam free!")}
       />
    );
    } 
    else return (
      <OnePageCheckout>
        <Helmet>
          <title>Doitforme | Questad subscribe</title>
        </Helmet>
        <Alert_ messages={alerts} duration={5000}/>
        <PageAlwaysVisible>
          <PageTitle>
              <h1>{t("Checkout")}</h1>
          </PageTitle>
          <CheckoutGrid>
            <Dailyprofit_Checkout />
            <Summary_ 
              amount ={currentCart?.length ? currentCart[0]?.amount : ""} 
              tax={tax ? tax?.taxAmount : ""} 
              total={tax?.totalAmount || ""} 
              currency={tax?.currency || "eur"}
            />
            {options?
              <CheckoutPaymentDetails>
                <Elements stripe={stripePromise} options={options}>
                  <h3>{t("Billing information")}</h3>
                  <Payment_Details 
                    showVatInput={showVatInput} 
                    setShowVatInput={setShowVatInput} 
                    customer={customer} 
                    setCustomer={setCustomer} 
                    selectedCountry={selectedCountry} 
                    setSelectedCountry={setSelectedCountry} 
                    total={tax?.totalAmount} 
                    currency={tax?.currency} 
                    setBillingFields={setBillingFields} 
                    billingFields={billingFields}
                  />
                </Elements> 
              </CheckoutPaymentDetails>
            :null}
          </CheckoutGrid>
        </PageAlwaysVisible>
      </OnePageCheckout>
  )
}
