
export const vatCountryCodes = {
  "AD": "AD",
  "AT": "AT",
  "BR": "BR",
  "BE": "BE",
  "BG": "BG",
  "HR": "HR",
  "CY": "CY",
  "CZ": "CZ",
  "DK": "DK",
  "EE": "EE",
  "FI": "FI",
  "FR": "FR",
  "DE": "DE",
  "GR": "EL",
  "HU": "HU",
  "IE": "IE",
  "IT": "IT",
  "LV": "LV",
  "LT": "LT",
  "LU": "LU",
  "MT": "MT",
  "NL": "NL",
  "NO": "NO",
  "PL": "PL",
  "PT": "PT",
  "RO": "RO",
  "RU": "RU",
  "RS": "RS",
  "SK": "SK",
  "SI": "SI",
  "ES": "ES",
  "SE": "SE",
  "CH": "CH",
  "GB": "GB"
}
